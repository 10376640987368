import { createSlice } from '@reduxjs/toolkit';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;

const initialState = {
  isLoading: false,
  error: null,

  eventTypeId: [],
  eventListByID: [],
  eventByID: [],
  eventTicketListByEventID: [],
  eventTicketByTicketID: [],
  eventDashboard: [],

  addBasic: null,
  updateBasic: null,
  addOrganiser: null,
  addLocation: null,
  addDateAndTime: null,
  addMedia: null,
  deleteMedia: null,
  deleteEvent: null,
  duplicationName: null,
  endorseEvent: null,

  addTicket: null,
  updateTicket: null,
  deleteTicket: null,

  addMerch: null,
  deleteMerch: null,
  ticketInd: null
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // Has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    checkDuplicationEventNameSuccess(state, action) {
      state.isLoading = false;
      state.duplicationName = action.payload;
    },

    addEventBasicInfoSuccess(state, action) {
      state.isLoading = false;
      state.addBasic = action.payload;
    },

    updateEventBasicInfoSuccess(state, action) {
      state.isLoading = false;
      state.updateBasic = action.payload;
    },

    addOrganiserInfoSuccess(state, action) {
      state.isLoading = false;
      state.addOrganiser = action.payload;
    },

    addEventLocationInfoSuccess(state, action) {
      state.isLoading = false;
      state.addLocation = action.payload;
    },

    addEventDateAndTimeSuccess(state, action) {
      state.isLoading = false;
      state.addDateAndTime = action.payload;
    },

    addEventMediaSuccess(state, action) {
      state.isLoading = false;
      state.addMedia = action.payload;
    },

    deleteEventMediaSuccess(state, action) {
      state.isLoading = false;
      state.deleteMedia = action.payload;
    },

    getEventListByIDSuccess(state, action) {
      state.isLoading = false;
      state.eventListByID = action.payload;
    },

    getEventByIDSuccess(state, action) {
      state.isLoading = false;
      state.eventByID = action.payload;
    },

    viewEventTypeSuccess(state, action) {
      state.isLoading = false;
      state.eventTypeId = action.payload;
    },

    deleteEventActionSuccess(state, action) {
      state.isLoading = false;
      state.deleteEvent = action.payload;
    },

    endorseEventActionSuccess(state, action) {
      state.isLoading = false;
      state.endorseEvent = action.payload;
    },

    getEventTicketListByEventIDSuccess(state, action) {
      state.isLoading = false;
      state.eventTicketListByEventID = action.payload;
    },

    getEventTicketByTicketIDSuccess(state, action) {
      state.isLoading = false;
      state.eventTicketByTicketID = action.payload;
    },

    addEventTicketSuccess(state, action) {
      state.isLoading = false;
      state.addTicket = action.payload;
    },

    updateEventTicketSuccess(state, action) {
      state.isLoading = false;
      state.updateTicket = action.payload;
    },

    deleteEventTicketSuccess(state, action) {
      state.isLoading = false;
      state.deleteTicket = action.payload;
    },

    addMerchandiseSuccess(state, action) {
      state.isLoading = false;
      state.addMerch = action.payload;
    },

    deleteMerchandiseSuccess(state, action) {
      state.isLoading = false;
      state.deleteMerch = action.payload;
    },

    getEventDashboardSuccess(state, action) {
      state.isLoading = false;
      state.eventDashboard = action.payload;
    },

    updateEventTicketActiveIndSuccess(state, action) {
      state.isLoading = false;
      state.ticketInd = action.payload;
    },

    clearEventActionSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.addBasic = null;
      state.updateBasic = null;
      state.addOrganiser = null;
      state.addLocation = null;
      state.addDateAndTime = null;
      state.addMedia = null;
      state.deleteMedia = null;
      state.duplicationName = null;
      state.endorseEvent = null;
      state.deleteEvent = null;

      state.addTicket = null;
      state.updateTicket = null;
      state.deleteTicket = null;

      state.addMerch = null;
      state.deleteMerch = null;
      state.ticketInd = null;
    },

    clearEventDataSuccess(state, action) {
      state.isLoading = false;
      state.error = null;

      state.eventListByID = [];
      state.eventByID = [];
    },

    clearEventTicketDataSuccess(state, action) {
      state.isLoading = false;
      state.error = null;

      state.eventTicketListByEventID = [];
      state.eventTicketByTicketID = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  Category_ViewList,
} = slice.actions;

// ----------------------------------------------------------------------

// --------------------- EVENT -------------------------

export function viewEventType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}General_ViewEventType`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.viewEventTypeSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.viewEventTypeSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventListByID(Id, projectId, productPerPage, page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_ItemListByMerchantID?MERCHANTID=${Id}&PROJECTID=${projectId}&PRODUCTPERPAGE=${productPerPage}&PAGE=${page}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getEventListByIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getEventListByIDSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventByID(eventId, projectId, productPerPage, page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_ItemListByEventID?EVENTID=${eventId}&PROJECTID=${projectId}&PRODUCTPERPAGE=${productPerPage}&PAGE=${page}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getEventByIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getEventByIDSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function checkDuplicationEventName(eventName, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_CheckDuplication?EVENTNAME=${eventName}&PROJECTID=${projectId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.checkDuplicationEventNameSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.checkDuplicationEventNameSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function addEventBasicInfo(eventData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    return fetch(
      `${url}Event_AddUpdateEvent_BasicInfo`
      , {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({       
          MODIFYBY: eventData.modifyBy,    
          PROJECTID: eventData.projectId,
          MERCHANTID: eventData.merchantId,

          EVENTID: eventData.eventId,
          EVENTNAME: eventData.eventName,

          EVENTDESCRIPTION: eventData.eventDescription,
          EVENTTAG: eventData.eventTag,
          EVENTTYPEID: eventData.eventTypeId,
          EVENTTERM: eventData.eventTerm,
          PURCHASEINFO: eventData.purchaseInfo,
          CURRENTSTAGE: eventData.currentStage
        })
      }
    )
      .then(response => response.json())
      .then(json => {
        const data = json[0];
        if (data && data.ReturnVal === 1) {
          dispatch(slice.actions.addEventBasicInfoSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.addEventBasicInfoSuccess([]));
          dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        }
      })
      .catch(error => dispatch(slice.actions.hasError(error)));
  };
}


export function updateEventBasicInfo(eventData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    return fetch(
      `${url}Event_AddUpdateEvent_BasicInfo`
      , {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({       
          MODIFYBY: eventData.modifyBy,    
          PROJECTID: eventData.projectId,
          MERCHANTID: eventData.merchantId,

          EVENTID: eventData.eventId,
          EVENTNAME: eventData.eventName,

          EVENTDESCRIPTION: eventData.eventDescription,
          EVENTTAG: eventData.eventTag,
          EVENTTYPEID: eventData.eventTypeId,
          EVENTTERM: eventData.eventTerm,
          PURCHASEINFO: eventData.purchaseInfo,
          CURRENTSTAGE: eventData.currentStage,
        })
      }
    )
      .then(response => response.json())
      .then(json => {
        const data = json[0];
        if (data && data.ReturnVal === 1) {
          dispatch(slice.actions.updateEventBasicInfoSuccess(JSON.parse(data.ReturnData)));
        } else {
          dispatch(slice.actions.updateEventBasicInfoSuccess([]));
          dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
        }
      })
      .catch(error => dispatch(slice.actions.hasError(error)));
  };
}

export function addOrganiserInfo(eventData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await fetch(
        `${url}Event_AddUpdateEvent_OrganiserInfo?MODIFYBY=${eventData.modifyBy}&EVENTID=${eventData.eventId}&EVENTORGANISER=${eventData.eventOrganiser}&EVENTORGANISERCONTACT=${eventData.eventOrganiserContact}&EVENTORGANISEREMAIL=${eventData.eventOrganiserEmail}&EVENTORGANISERWEBSITE=${eventData.eventOrganiserWebsite}&EVENTORGANISERLOGO=${eventData.eventOrganiserLogo}&CURRENTSTAGE=${eventData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addOrganiserInfoSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addOrganiserInfoSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addEventLocationInfo(eventData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_AddUpdateEvent_LocationInfo?MODIFYBY=${eventData.modifyBy}&EVENTID=${eventData.eventId}&EVENTLOCATIONNAME=${eventData.eventLocationName}&EVENTADDRESS1=${eventData.eventAddress1}&EVENTADDRESS2=${eventData.eventAddress2}&EVENTSTATE=${eventData.eventState}&EVENTCITY=${eventData.eventCity}&EVENTPOSCODE=${eventData.eventPosCode}&EVENTCOUNTRYID=${eventData.eventCountryId}&EVENTLONGITUDE=${eventData.eventLongitude}&EVENTLATITUDE=${eventData.eventLatitude}&CURRENTSTAGE=${eventData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addEventLocationInfoSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(data.ReturnSqlError));
        dispatch(slice.actions.addEventLocationInfoSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addEventDateAndTime(eventData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_AddUpdateEvent_DateTimeInfo?MODIFYBY=${eventData.modifyBy}&EVENTID=${eventData.eventId}&EVENTREGISTERBEGINDATE=${eventData.eventRegisterBeginDate}&EVENTREGISTERENDDATE=${eventData.eventRegisterEndDate}&EVENTSTARTDATE=${eventData.eventStartDate}&EVENTENDDATE=${eventData.eventEndDate}&EVENTSTARTTIME=${eventData.eventStartTime}&EVENTENDTIME=${eventData.eventEndTime}&CURRENTSTAGE=${eventData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addEventDateAndTimeSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.hasError(data.ReturnSqlError));
        dispatch(slice.actions.addEventDateAndTimeSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addEventMedia(eventData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_AddUpdateEvent_Media?MODIFYBY=${eventData.modifyBy}&EVENTID=${eventData.eventId}&TICKETID=${eventData.ticketId}&PROJECTID=${eventData.projectId}&MEDIATYPE=${eventData.mediaType}&MEDIAURL=${eventData.mediaUrl}&MEDIADESCRIPTION=${eventData.mediaDescription}&EVENTCOVERIMAGE=${eventData.eventCoverImage}&EVENTCOVERIMAGEALT=${eventData.eventCoverImageAlt}&CURRENTSTAGE=${eventData.currentStage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addEventMediaSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addEventMediaSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEventMedia(modifyBy, eventId, projectId, eventMediaId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_DeleteEvent_Media?MODIFYBY=${modifyBy}&EVENTID=${eventId}&PROJECTID=${projectId}&EVENTMEDIAID=${eventMediaId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteEventMediaSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteEventMediaSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEventAction(modifyBy, eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_DeleteEvent?MODIFYBY=${modifyBy}&EVENTID=${eventId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteEventActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteEventActionSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function endorseEventAction(modifyBy, eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_EndorseEvent?MODIFYBY=${modifyBy}&EVENTID=${eventId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.endorseEventActionSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.endorseEventActionSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------- EVENT TICKET -------------------------

export function getEventTicketListByEventID(eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_ViewTicketTypeByEventIDByPlatform?EVENTID=${eventId}&PLATFORM=CMS`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getEventTicketListByEventIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getEventTicketListByEventIDSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventTicketByTicketID(ticketId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_ViewTicketByTicketID?TICKETID=${ticketId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getEventTicketByTicketIDSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getEventTicketByTicketIDSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addEventTicket(ticketData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_AddEventTicket?MODIFYBY=${ticketData.modifyBy}&PROJECTID=${ticketData.projectId}&EVENTID=${ticketData.eventId}&TICKETNAME=${ticketData.ticketName}&TICKETDESCRIPTION=${ticketData.ticketDescription}&TICKETTERMS=${ticketData.ticketTerms}&TICKETALLOCATE=${ticketData.ticketAllocate}&STARTDATE=${ticketData.startDate}&ENDDATE=${ticketData.endDate}&NORMALPRICE=${ticketData.normalPrice}&WEEKENDPRICE=${ticketData.weekendPrice}&HOLIDAYPRICE=${ticketData.holidayPrice}&ISDAILYRENEW=${ticketData.isDailyRenew}&TICKETIMAGE=${ticketData.ticketImage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addEventTicketSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addEventTicketSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEventTicket(ticketData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_UpdateEventTicket?MODIFYBY=${ticketData.modifyBy}&TICKETID=${ticketData.ticketId}&TICKETNAME=${ticketData.ticketName}&TICKETDESCRIPTION=${ticketData.ticketDescription}&TICKETTERMS=${ticketData.ticketTerms}&TICKETALLOCATE=${ticketData.ticketAllocate}&STARTDATE=${ticketData.startDate}&ENDDATE=${ticketData.endDate}&NORMALPRICE=${ticketData.normalPrice}&WEEKENDPRICE=${ticketData.weekendPrice}&HOLIDAYPRICE=${ticketData.holidayPrice}&ISDAILYRENEW=${ticketData.isDailyRenew}&TICKETIMAGE=${ticketData.ticketImage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateEventTicketSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateEventTicketSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEventTicket(modifyBy, ticketId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_DeleteEventTicket?MODIFYBY=${modifyBy}&TICKETID=${ticketId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteEventTicketSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteEventTicketSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// --------------------- EVENT MERCH -------------------------
export function addMerchandise(eventData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_AddMerchandise?EVENTID=${eventData.eventId}&PRODUCTID=${eventData.productId}&PRODUCTVARIATIONDETAILID=${eventData.productVariationDetailId}&DISCOUNTEDPRICE=${eventData.discountedPrice}&ISCUSTOMIZEPRICEIND=${eventData.isCustomizePriceInd}&MODIFYBY=${eventData.modifyBy}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addMerchandiseSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addMerchandiseSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteMerchandise(modifyBy, productId, eventId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_DeleteMerchandise?MODIFYBY=${modifyBy}&PRODUCTID=${productId}&EVENTID=${eventId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteMerchandiseSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteMerchandiseSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventDashboard(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Reporting_EventDashboard?USERID=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.getEventDashboardSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.getEventDashboardSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateEventTicketActiveInd(userId, ticketID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Event_UpdateEventTicketActiveInd?MODIFYBY=${userId}&TICKETID=${ticketID}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateEventTicketActiveIndSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateEventTicketActiveIndSuccess([]));
        dispatch(slice.actions.hasError(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearEventAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearEventActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearEventData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearEventDataSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearEventTicketData() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearEventTicketDataSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}