import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutocompleteTextFieldCustomise.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.node,
  onEnterHit: PropTypes.func,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  options: PropTypes.array,
};

export default function RHFAutocompleteTextFieldCustomise({ name, label, helperText, onEnterHit,
  optionLabel = 'label',
  optionValue = 'value',
  options,
   ...other }) {
  const { control, setValue } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
        {...field}
          // multiple
          freeSolo
          options={options}
          autoHighlight
          onChange={(event, newValue) => setValue(name, newValue, { shouldValidate: true })}
          renderInput={params => (
            <TextField
            {...params}
            label={label}
            // size='small'
            placeholder=""
            error={!!error}
            helperText={error ? error?.message : helperText}
            variant='outlined'
          />
          )}
          {...other}
        />
      )}
    />
  );
}