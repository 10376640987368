import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl
const PROJECTID = ServerConfiguration.PROJECTID;
const initialState = {
  isLoading: false,
  error: null,
  userList: [],
  notification: [],
  notificationAction: null,
  
  forgetPasswordAction: null,
  forgetPasswordVerified: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // View User List
    getUserListingSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getUserListingByModuleSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    viewNotificationByIDSuccess(state, action) {
      state.isLoading = false;
      state.notification = action.payload;
    },

    updateNotificationStatusSuccess(state, action) {
      state.isLoading = false;
      state.notificationAction = action.payload;
    },

    clearUserActionSuccess(state, action) {
      state.isLoading = false;
      state.promoStatus = null;
      state.deletePromo = null;
      state.addPromo = null;
      state.updatePromo = null;
      state.error = null;
      state.notificationAction = null;
    },

    
    forgetPasswordOTPsent(state, action) {
      state.isLoading = false;
      state.forgetPasswordAction = action.payload;
    },

    forgetPasswordVerifyOTP(state, action) {
      state.isLoading = false;
      state.forgetPasswordVerified = action.payload;
    },

    unsubscribeOTPActionSuccess(state, action) {
      state.isLoading = false;
      state.forgetPasswordAction = null;
      state.forgetPasswordVerified = null;
      state.registerAction = null;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  Category_ViewList,
} = slice.actions;

// ----------------------------------------------------------------------


export function getUserListingByType(type, typeValue, projectId, userId, userRoleId, listPerPage, page) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ProfileListByType?TYPE=${type}&TYPEVALUE=${typeValue}&USERID=${userId}&USERROLEID=${userRoleId}&LISTPERPAGE=${listPerPage}&PAGE=${page}&PROJECTID=${projectId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getUserListingSuccess(returndata));
      } else {
        dispatch(slice.actions.getUserListingSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserListingByModule(type, typeValue, projectId, userId, accessCategoryID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ProfileListByModule?TYPE=${type}&TYPEVALUE=${typeValue}&USERID=${userId}&ACCESSCATEGORYID=${accessCategoryID}&PROJECTID=${projectId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getUserListingByModuleSuccess(returndata));
      } else {
        dispatch(slice.actions.getUserListingByModuleSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewNotificationByID(userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ViewNotification?USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.viewNotificationByIDSuccess(returndata));
      } else {
        dispatch(slice.actions.viewNotificationByIDSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function updateNotificationStatus(notificationId, projectId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateNotificationStatus?NOTIFICATIONID=${notificationId}&PROJECTID=${projectId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const returndata = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updateNotificationStatusSuccess(returndata));
      } else {
        dispatch(slice.actions.updateNotificationStatusSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function clearUserAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearUserActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// FORGET PASSWORD
export function forgetPasswordOTP(UserID, TYPE, UpdatedValue) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_SentOTPVerificationWithProjectID?USERID=${UserID}&TYPE=${TYPE}&VALIDATIONFIELD=${UpdatedValue}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if (data && data.ReturnVal === 1) {
        // setUser(data.ReturnData)
        dispatch(slice.actions.forgetPasswordOTPsent(JSON.parse(data.ReturnData)[0]));

      } else {
        dispatch(slice.actions.forgetPasswordOTPsent(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function forgetPasswordChangePassword(USEREMAIL, OTP, PASSWORD) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_ForgetPassword_VerifyOTPWithProjectID?USEREMAIL=${USEREMAIL}&OTP=${OTP}&PASSWORD=${PASSWORD}&PROJECTID=${PROJECTID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        // setUser(data.ReturnData)
        dispatch(slice.actions.forgetPasswordVerifyOTP(JSON.parse(data.ReturnData)[0]));

      } else {
        dispatch(slice.actions.forgetPasswordVerifyOTP(data.ReturnSqlError));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unsubscribeOTPAction() {
  return async (dispatch) => {
      dispatch(slice.actions.unsubscribeOTPActionSuccess());
  };
}


// ----------------------------------------------------------------------

