// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  PageRestrict: '/restriction',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  categoryManagement: path(ROOTS_DASHBOARD, '/category-management'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    statement: path(ROOTS_DASHBOARD, '/e-commerce/statement'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    shopprofile: path(ROOTS_DASHBOARD, '/e-commerce/shopprofile'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    endorseList: path(ROOTS_DASHBOARD, '/e-commerce/endorseList'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/e-commerce/product/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/e-commerce/product/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product/dashboard'),
    // shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    // shopprofile: path(ROOTS_DASHBOARD, '/e-commerce/shopprofile'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    endorseList: path(ROOTS_DASHBOARD, '/product/endorseList'),
    // checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/product/${id}/endorsement`),
    viewDetail: (id) => path(ROOTS_DASHBOARD, `/product/${id}/view`),
    edit: (id) => path(ROOTS_DASHBOARD, `/product/${id}/edit`),
    userList: path(ROOTS_DASHBOARD, '/product/userList'),
    // demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    // demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  stock: {
    root: path(ROOTS_DASHBOARD, '/stock/list'),
    list: path(ROOTS_DASHBOARD, '/stock/list'),
    new: path(ROOTS_DASHBOARD, '/stock/new'),
    edit: (variationDetailId, productId, storageId) =>
      path(ROOTS_DASHBOARD, `/stock/${variationDetailId}/${productId}/${storageId}/edit`),
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order/list'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    viewDetail: (id) => path(ROOTS_DASHBOARD, `/order/${id}`),
    // edit: (orderId) => path(ROOTS_DASHBOARD, `/order/${orderId}/edit`),
  },
  promotion: {
    root: path(ROOTS_DASHBOARD, '/promotion/list'),
    list: path(ROOTS_DASHBOARD, '/promotion/list'),
    new: path(ROOTS_DASHBOARD, '/promotion/new'),
    // viewDetail: (id) => path(ROOTS_DASHBOARD, `/order/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/promotion/${id}/edit`),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/setting/category'),
    category: path(ROOTS_DASHBOARD, '/setting/category'),
    // new: path(ROOTS_DASHBOARD, '/promotion/new'),
    // viewDetail: (id) => path(ROOTS_DASHBOARD, `/order/${id}`),
    // edit: (id) => path(ROOTS_DASHBOARD, `/promotion/${id}/edit`),
  },
  event: {
    root: path(ROOTS_DASHBOARD, '/event/dashboard'),
    list: path(ROOTS_DASHBOARD, '/event/list'),
    new: (id) => path(ROOTS_DASHBOARD, `/event/${id}/new`),
    edit: (id) => path(ROOTS_DASHBOARD, `/event/${id}/edit`),

    ticketList: (id) => path(ROOTS_DASHBOARD, `/event/${id}/ticketList`),
    ticketNew: (id, ticketId) => path(ROOTS_DASHBOARD, `/event/${id}/ticket/${ticketId}/new`),
    ticketEdit: (id, ticketId) => path(ROOTS_DASHBOARD, `/event/${id}/ticket/${ticketId}/edit`),

    bookingList: path(ROOTS_DASHBOARD, '/event/bookingList'),
    bookingDetails: (id) => path(ROOTS_DASHBOARD, `/event/${id}/bookingDetails`),
  },
  accomodation: {
    root: path(ROOTS_DASHBOARD, '/accomodation/dashboard'),
    list: path(ROOTS_DASHBOARD, '/accomodation/list'),
    calendar: path(ROOTS_DASHBOARD, '/accomodation/hotel/calendar'),
    hotelRoom: (id) => path(ROOTS_DASHBOARD, `/accomodation/hotel/${id}/roomList`),
    // endorseList: path(ROOTS_DASHBOARD, '/product/endorseList'),
    hotelNew: (id) => path(ROOTS_DASHBOARD, `/accomodation/hotel/${id}/new`),
    hotelEdit: (id) => path(ROOTS_DASHBOARD, `/accomodation/hotel/${id}/edit`),
    hotelSetting: (id) => path(ROOTS_DASHBOARD, `/accomodation/hotel/${id}/setting`),
    roomNew: (id, roomId) => path(ROOTS_DASHBOARD, `/accomodation/hotel/${id}/room/${roomId}/new`),
    roomEdit: (id, roomId) =>
      path(ROOTS_DASHBOARD, `/accomodation/hotel/${id}/room/${roomId}/edit`),
    bookingList: path(ROOTS_DASHBOARD, '/accomodation/bookingList'),
    bookingListView: (id) => path(ROOTS_DASHBOARD, `/accomodation/booking/${id}`),
    property: path(ROOTS_DASHBOARD, '/accomodation/property'),
    promotion: (id) => path(ROOTS_DASHBOARD, `/accomodation/property/${id}/promotion`),
    promotionView: (id, promoId) =>
      path(ROOTS_DASHBOARD, `/accomodation/property/${id}/promotion/${promoId}`),
    promotionEdit: (id, promoId) =>
      path(ROOTS_DASHBOARD, `/accomodation/property/${id}/promotion/${promoId}/edit`),
    promotionNew: (id) => path(ROOTS_DASHBOARD, `/accomodation/property/${id}/promotion/new`),

    userList: path(ROOTS_DASHBOARD, '/accomodation/userList'),
    // view: (id) => path(ROOTS_DASHBOARD, `/product/${id}/endorsement`),
    // viewDetail: (id) => path(ROOTS_DASHBOARD, `/product/${id}/view`),
    // edit: (id) => path(ROOTS_DASHBOARD, `/product/${id}/edit`),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/';

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/';

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/';

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1';
