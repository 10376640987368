import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl
const initialState = {
  isLoading: false,
  error: null,
  stockList: [],
  variationStockList:[],
  gridStore:[],

  addStock: null,
  updateStock: null,
  deleteStock: null,
  stockStatusAction: null,

  addStockAction: null,
  stockDetailList:null,
  updateStockStatus: null
};

const slice = createSlice({
  name: 'stock',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // View Stock List
    getStockListSuccess(state, action) {
      state.isLoading = false;
      state.stockList = action.payload;
    },

    getVariationStockListSuccess(state, action) {
      state.isLoading = false;
      state.variationStockList = action.payload;
    },

    getGridStoreListingSuccess(state, action) {
      state.isLoading = false;
      state.gridStore = action.payload;
    },

    updateStockDetailStatusSuccess(state, action) {
      state.isLoading = false;
      state.stockStatusAction = action.payload;
    },

    addProductVariationStockSuccess(state, action) {
      state.isLoading = false;
      state.addStockAction = action.payload;
    },

    viewVariationStockByDetailIDSuccess(state, action) {
      state.isLoading = false;
      state.stockDetailList = action.payload;
    },

    updateVariationStockSuccess(state, action) {
      state.isLoading = false;
      state.updateStockAction = action.payload;
    },

    updateVariationStockStatusSuccess(state, action) {
      state.isLoading = false;
      state.updateStockStatus = action.payload;
    },

    clearStockListActionSuccess(state, action) {
      state.isLoading = false;
      state.addStock = null;
      state.updateStock = null;
      state.deleteStock = null;
      state.stockStatusAction = null;
      state.addStockAction = null;
      state.stockDetailList = null;
      state.updateStockAction = null;
      state.updateStockStatus = null;

    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  Category_ViewList,
} = slice.actions;

// ----------------------------------------------------------------------

export function getStockList(projectID, productID, page, productPerPage, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ItemListWithProductVariationStock?PROJECTID=${projectID}&PRODUCTID=${productID}&PAGE=${page}&PRODUCTPERPAGE=${productPerPage}&USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getStockListSuccess(categories));
      } else {
        dispatch(slice.actions.getStockListSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getVariationStockList(projectID, page, productPerPage) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ItemListWithVariation?PROJECTID=${projectID}&PAGE=${page}&PRODUCTPERPAGE=${productPerPage}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getVariationStockListSuccess(categories));
      } else {
        dispatch(slice.actions.getVariationStockListSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getGridStoreListing(projectId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Storage_GridStorageList?PROJECTID=${projectId}&USERID=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.getGridStoreListingSuccess(categories));
      } else {
        dispatch(slice.actions.getGridStoreListingSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStockDetailStatus(variationStockId, type, value, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_ItemListWithProductVariationStock?PRODUCTVARIATIONSTOCKID=${variationStockId}&MODIFIEDBY=${userId}&TYPE=${type}&VALUE=${value}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updateStockDetailStatusSuccess(categories));
      } else {
        dispatch(slice.actions.updateStockDetailStatusSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function addProductVariationStock(remark, variationDetailId, productStock, productVariationCost, gridStorageID, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      
      const response = await fetch(
        `${url}Product_AddProductVariationStock?COINTAINERID=${remark}&USERID=${userId}&PRODUCTVARIATIONDETAILID=${variationDetailId}&PRODUCTSTOCK=${productStock}&PRODUCTVARIATIONCOST=${productVariationCost}&GRIDSTORAGEID=${gridStorageID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.addProductVariationStockSuccess(categories));
      } else {
        dispatch(slice.actions.addProductVariationStockSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function viewVariationStockByDetailID(variationDetailId, productId, projectID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
  
      const response = await fetch(
        `${url}Product_ItemListWithProductVariationStockDetailList?PRODUCTVARIATIONDETAILID=${variationDetailId}&PRODUCTID=${productId}&PROJECTID=${projectID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.viewVariationStockByDetailIDSuccess(categories));
      } else {
        dispatch(slice.actions.viewVariationStockByDetailIDSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateVariationStock(variationStockID, userId, approvedBy, productStock, productVariationCost,gridStorageID ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
  
      const response = await fetch(
        `${url}Product_UpdateProductVariationStock?PRODUCTVARIATIONSTOCKID=${variationStockID}&USERID=${userId}&APPROVEDBY=${approvedBy}&PRODUCTSTOCK=${productStock}&PRODUCTVARIATIONCOST=${productVariationCost}&GRIDSTORAGEID=${gridStorageID}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updateVariationStockSuccess(categories));
      } else {
        dispatch(slice.actions.updateVariationStockSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateVariationStockStatus(variationStockID, type, value, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
  
      const response = await fetch(
        `${url}Product_UpdateStockDetailStatus?PRODUCTVARIATIONSTOCKID=${variationStockID}&TYPE=${type}&VALUE=${value}&MODIFIEDBY=${userId}`
      )
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        const categories = JSON.parse(data.ReturnData);
        dispatch(slice.actions.updateVariationStockStatusSuccess(categories));
      } else {
        dispatch(slice.actions.updateVariationStockStatusSuccess([]));
        dispatch(slice.actions.hasError(JSON.parse(data.ReturnData)[0].ReturnSqlError));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearStockListAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
           dispatch(slice.actions.clearStockListActionSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// ----------------------------------------------------------------------

