import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Stack,
  Box
} from '@mui/material';

// ----------------------------------------------------------------------

RHFRadioCardGroup.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  optionDesc: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  spacing: PropTypes.number,
  helperText: PropTypes.node,
  grid: PropTypes.object,
};

export default function RHFRadioCardGroup({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  optionLabel,
  optionValue,
  optionDesc,
  grid,
  ...other
}) {
  const { control } = useFormContext();
  const labelledby = label ? `${name}-${label}` : '';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" id={labelledby} sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <RadioGroup {...field} aria-labelledby={labelledby} row={row} {...other}>

            <Box gap={1} display="grid" gridTemplateColumns={grid}>
              {options.map((option, index) => (
                <Stack sx={{ border: '1px solid #d3d3d3 ', borderRadius: '10px', padding: '10px' }} key={`${name}_${index}`}>
                  <FormControlLabel
                    key={option[`${optionValue}`]}
                    value={option[`${optionValue}`]}
                    control={<Radio />}
                    label={option[`${optionLabel}`]}
                    sx={{
                      '&:not(:last-of-type)': {
                        mb: spacing || 0,
                      },
                      ...(row && {
                        mr: 0,
                        '&:not(:last-of-type)': {
                          mr: spacing || 2,
                        },
                      }),
                    }}
                  />
                  {(option[`${optionDesc}`]) && (
                    <FormHelperText sx={{ paddingTop: 0, paddingLeft: 2 }} >
                      {option[`${optionDesc}`]}
                    </FormHelperText>
                  )}
                </Stack>
              ))}
            </Box>

          </RadioGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
