// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------

  {
    subheader: 'general',
    items: [{ title: 'user management', path: PATH_DASHBOARD.product.userList, icon: ICONS.user }],
  },

  {
    subheader: 'event product module',
    items: [
      // { title: 'dashboard', path: PATH_DASHBOARD.product.root, icon: ICONS.dashboard },
      {
        title: 'product management',
        path: PATH_DASHBOARD.product.list,
        icon: ICONS.cart,
      },
      { title: 'stock management', path: PATH_DASHBOARD.stock.list, icon: ICONS.banking },
      // { title: 'order management', path: PATH_DASHBOARD.order.list, icon: ICONS.booking },
      // { title: 'promotion management', path: PATH_DASHBOARD.promotion.list, icon: ICONS.ecommerce },
    ],
  },

  {
    subheader: 'product module',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.product.root, icon: ICONS.dashboard },
      {
        title: 'product management',
        path: PATH_DASHBOARD.product.list,
        icon: ICONS.cart,
      },

      { title: 'stock management', path: PATH_DASHBOARD.stock.list, icon: ICONS.banking },
      { title: 'order management', path: PATH_DASHBOARD.order.list, icon: ICONS.booking },
      // { title: 'order management', path: PATH_DASHBOARD.stock.list, icon: ICONS.ecommerce },
      // { title: 'transaction', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // { title: 'store profile', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'stock management', path: PATH_DASHBOARD.stock.list, icon: ICONS.banking },
      { title: 'promotion management', path: PATH_DASHBOARD.promotion.list, icon: ICONS.ecommerce },
      { title: 'category management', path: PATH_DASHBOARD.setting.category , icon: ICONS.ecommerce },
      // { title: 'statement', path: PATH_DASHBOARD.eCommerce.statement, icon: ICONS.ecommerce },
      // { title: 'settings', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
      // {
      //   title: 'setting',
      //   path: PATH_DASHBOARD.setting.category,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'category management', path: PATH_DASHBOARD.setting.category },
      //     // { title: 'order management', path: PATH_DASHBOARD.stock.list },
      //     // { title: 'endorsement', path: PATH_DASHBOARD.product.endorseList },
      //   ],
      // },

      // {
      //   title: 'kanban',
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban,
      // },
    ],
  },

  {
    subheader: 'event module',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.event.root, icon: ICONS.dashboard },
      { title: 'event management', path: PATH_DASHBOARD.event.list, icon: ICONS.cart },
      { title: 'booking management', path: PATH_DASHBOARD.event.bookingList, icon: ICONS.user },
    ],
  },

  {
    subheader: 'accomodation module',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.accomodation.root, icon: ICONS.dashboard },
      {
        title: 'property management',
        path: PATH_DASHBOARD.accomodation.list,
        icon: ICONS.cart,
      },
      {
        title: 'calendar management',
        path: PATH_DASHBOARD.accomodation.calendar,
        icon: ICONS.cart,
      },
      {
        title: 'booking management',
        path: PATH_DASHBOARD.accomodation.bookingList,
        icon: ICONS.booking,
      },
      // { title: 'promotion management', path: PATH_DASHBOARD.accomodation.property, icon: ICONS.ecommerce },
      // { title: 'user management', path: PATH_DASHBOARD.accomodation.userList, icon: ICONS.user },
      // {
      //   title: 'report management',
      //   path: PATH_DASHBOARD.accomodation.bookingList,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'booking report', path: PATH_DASHBOARD.setting.category },
      //     { title: 'sale report', path: PATH_DASHBOARD.setting.category },
      //   ]
      // },

      // { title: 'stock management', path: PATH_DASHBOARD.stock.list, icon: ICONS.banking },
      // { title: 'order management', path: PATH_DASHBOARD.order.list, icon: ICONS.booking },
      // // { title: 'order management', path: PATH_DASHBOARD.stock.list, icon: ICONS.ecommerce },
      // // { title: 'transaction', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // // { title: 'store profile', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // // { title: 'stock management', path: PATH_DASHBOARD.stock.list, icon: ICONS.banking },
      // { title: 'promotion management', path: PATH_DASHBOARD.promotion.list, icon: ICONS.ecommerce },
      // { title: 'statement', path: PATH_DASHBOARD.eCommerce.statement, icon: ICONS.ecommerce },
      // { title: 'user management', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
      // // { title: 'settings', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
      // {
      //   title: 'setting',
      //   path: PATH_DASHBOARD.stock.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'category management', path: PATH_DASHBOARD.setting.category },
      //     // { title: 'order management', path: PATH_DASHBOARD.stock.list },
      //     // { title: 'endorsement', path: PATH_DASHBOARD.product.endorseList },
      //   ],
      // },
    ],
  },

  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'ecommerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //     { title: 'file', path: PATH_DASHBOARD.general.file, icon: ICONS.file },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.new },
  //         { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // E-COMMERCE
  //     {
  //       title: 'ecommerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'shop profile', path: PATH_DASHBOARD.eCommerce.shopprofile },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //       ],
  //     },

  //     // INVOICE
  //     {
  //       title: 'invoice',
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: 'list', path: PATH_DASHBOARD.invoice.list },
  //         { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.invoice.new },
  //         { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  //     // BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.demoView },
  //         { title: 'create', path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //     {
  //       title: 'File manager',
  //       path: PATH_DASHBOARD.fileManager,
  //       icon: ICONS.folder,
  //     },
  //     {
  //       title: 'Category Management',
  //       path: PATH_DASHBOARD.categoryManagement,
  //       icon: ICONS.folder,
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">+32</Label>,
  //     },
  //     {
  //       title: 'chat',
  //       path: PATH_DASHBOARD.chat.root,
  //       icon: ICONS.chat,
  //     },
  //     {
  //       title: 'calendar',
  //       path: PATH_DASHBOARD.calendar,
  //       icon: ICONS.calendar,
  //     },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban,
  //     },
  //   ],
  // },

  // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.lock,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level',
  //       path: '#/dashboard/menu_level',
  //       icon: ICONS.menuItem,
  //       children: [
  //         {
  //           title: 'menu_level_2a',
  //           path: '#/dashboard/menu_level/menu_level_2a',
  //         },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'item_disabled',
  //       path: '#disabled',
  //       icon: ICONS.disabled,
  //       disabled: true,
  //     },

  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.label,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     {
  //       title: 'item_external_link',
  //       path: 'https://www.google.com/',
  //       icon: ICONS.external,
  //     },
  //     {
  //       title: 'blank',
  //       path: PATH_DASHBOARD.blank,
  //       icon: ICONS.blank,
  //     },
  //   ],
  // },
];

export default function getNavConfig(accessCategory, UserTypeID) {
  let accessPage =
    UserTypeID === 1 ? [...navConfig.filter((role) => role.subheader === 'general')] : [];

  if (accessCategory && accessCategory !== undefined) {
    const isEvent = (accessCategory || "-").split(',').includes('2');
    accessCategory.split(',').forEach((page) => {
      switch (Number(page)) {
        case 1:
          if (isEvent === true)
            accessPage = [
              ...accessPage,
              ...navConfig.filter((role) => role.subheader === 'event product module'),
            ];
          else
            accessPage = [
              ...accessPage,
              ...navConfig.filter((role) => role.subheader === 'product module'),
            ];

          break;
        case 3:
          accessPage = [
            ...accessPage,
            ...navConfig.filter((role) => role.subheader === 'accomodation module'),
          ];
          break;

        case 2:
          accessPage = [
            ...accessPage,
            ...navConfig.filter((role) => role.subheader === 'event module'),
          ];
          break;

        default:
          break;
      }
    });
  }

  return accessPage;
}
