import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
import { setUser } from '../../auth/utils';
// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  acoountProfile: [],
  openModal: false,
  selectedEventId: null,
  selectedRange: null,
};

const slice = createSlice({
  name: 'accountProfile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    updateAccountProfileSuccess(state, action) {
      state.isLoading = false;
      state.acoountProfile = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function updateAccountProfile(Data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_UpdateMerchantProfile?USERID=${Data.UserID}&FIRSTNAME=${Data.firstname}&LASTNAME=${Data.lastname}&USERGENDER=${Data.gender}&USERDOB=${Data.dob}&USERNRIC=${Data.nric}&USERCONTACTNO=${Data.contactnumber}&USEREMAIL=${Data.email}&SHOPBANK=${Data.shopBank}&SHOPBANKACCOUNTNAME=${Data.bankAccountName}&SHOPBANKACCOUNTNO=${Data.bankAccountNumber}&SHOPBANKACCOUNTHEADER=${Data.bankHeaderImage}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];

      if(data && data.ReturnVal){
        setUser(data.ReturnData);
      }

      dispatch(slice.actions.updateAccountProfileSuccess(data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
