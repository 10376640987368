import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;
const initialState = {
  isLoading: false,
  error: null,
  addVariationAction: null,
  updateVariationAction: null,
  deleteVariationAction: null,
  deleteVariationDetailsAction: null,
  sentOTPverification: null,
};

const slice = createSlice({
  name: 'variation',
  initialState,
  reducers: {
    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    addVariationSuccess(state, action) {
      state.isLoading = false;
      state.addVariationAction = action.payload;
    },

    updateVariationSuccess(state, action) {
      state.isLoading = false;
      state.updateVariationAction = action.payload;
    },

    // updateVariationSuccess(state, action) {
    //   state.isLoading = false;
    //   state.updateVariationAction = action.payload;
    // },

    deleteVariationSuccess(state, action) {
      state.isLoading = false;
      state.deleteVariationAction = action.payload;
    },

    deleteVariationDetailsSuccess(state, action) {
      state.isLoading = false;
      state.deleteVariationDetailsAction = action.payload;
    },

    clearVariationActionSuccess(state, action) {
      state.isLoading = false;
      state.deleteVariationAction = null;
      state.deleteVariationDetailsAction = null;
      state.updateVariationAction = null;
      state.addVariationAction = null;
    },

    userSentOTPVerificationSuccess(state, action) {
      state.isLoading = false;
      state.sentOTPverification = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { Category_ViewList } = slice.actions;

// ----------------------------------------------------------------------

export function addVariation(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_AddProductVariationDetail?PRODUCTVARIATIONID=${productData.variationId}&PRODUCTID=${productData.productId}&CUSTOMIZABLE=${productData.optionCustomize}&VALUE=${productData.optionValue}&PRODUCTSTOCK=${productData.optionStock}&PRODUCTVARIATIONSKU=${productData.optionSku}&PRODUCTVARIATIONPRICE=${productData.optionPrice}&USERID=${productData.userId}`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.addVariationSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.addVariationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateVariation(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_UpdateProductVariationDetailWithVariation?PRODUCTVARIATIONDETAILID=${productData.optionId}&VARIATIONID=${productData.variationId}&CUSTOMIZABLE=${productData.optionCustomize}&VALUE=${productData.optionValue}&PRODUCTSTOCK=${productData.optionStock}&PRODUCTVARIATIONSKU=${productData.optionSku}&PRODUCTVARIATIONPRICE=${productData.optionPrice}&USERID=${productData.userId}`
      );

      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.updateVariationSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.updateVariationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVariationDetails(variationDetailId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_DeleteProductVariationDetails?PRODUCTVARIATIONDETAILID=${variationDetailId}&USERID=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteVariationDetailsSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteVariationDetailsSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteVariation(variationId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_DeleteProductVariation?PRODUCTVARIATIONID=${variationId}&USERID=${userId}`
      );
      const json = await response.json();
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
        dispatch(slice.actions.deleteVariationSuccess(JSON.parse(data.ReturnData)));
      } else {
        dispatch(slice.actions.deleteVariationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function clearVariationAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.clearVariationActionSuccess([]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function sendEmail_OTP(Data) {
  console.log('Data',Data)
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}User_SentOTPVerification?USERID=${Data.UserID}&TYPE=${'FORGETPASSWORD'}&VALIDATIONFIELD=${Data.email}`
      );
      
      const json = await response.json();
      const data = JSON.parse(json)[0];

      console.log('json',json)
      console.log('data',data)

      dispatch(slice.actions.userSentOTPVerificationSuccess([]));

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}