import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';
// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl;

const initialState = {
  isLoading: false,
  error: null,
  countries: [],
 
};

const slice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getCountries(state, action) {
      state.isLoading = false;
      state.countries = action.payload;

    },
    
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export function getCountries() {

    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
          const response = await fetch(
            `${url}General_CountryList`
          );
          const json = await response.json();
          const data = JSON.parse(JSON.parse(json)[0].ReturnData);

          dispatch(slice.actions.getCountries(data))
        } catch (error) {
          dispatch(slice.actions.hasError(error));
        }
      };

  };



