import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import {
  Box,
  Checkbox,
  FormLabel,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel, Stack
} from '@mui/material';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export function RHFCheckbox({ name, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <FormControlLabel control={<Checkbox {...field} checked={field.value} />} {...other} />

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  spacing: PropTypes.number,
  helperText: PropTypes.node,
};

export function RHFMultiCheckbox({ row, name, label, options, spacing, helperText, ...other }) {
  const { control } = useFormContext();

  const getSelected = (selectedItems, item) => {
    const data = selectedItems !== undefined && selectedItems ? selectedItems : []
    let returnData = []
    if (data.includes(item)) {

      returnData = data.filter((x) => x !== item)
    } else {
      returnData = [...data, item]
    }

    return returnData
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <FormGroup
            sx={{
              ...(row && {
                flexDirection: 'row',
              }),
              '& .MuiFormControlLabel-root': {
                '&:not(:last-of-type)': {
                  mb: spacing || 0,
                },
                ...(row && {
                  mr: 0,
                  '&:not(:last-of-type)': {
                    mr: spacing || 2,
                  },
                }),
              },
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={field.value && field.value !== undefined && field.value.includes(option.value)}
                    disabled={!option.isEnable}
                    onChange={() => field.onChange(getSelected(field.value, option.value))}
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}



RHFMultiCheckboxCustomise.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  spacing: PropTypes.number,
  helperText: PropTypes.node,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  value: PropTypes.array,
};

export function RHFMultiCheckboxCustomise({ row, name, label, options, spacing, helperText,
  optionLabel = 'label',
  optionValue = 'value',
  value,
  ...other }) {
  const { control } = useFormContext();

  const getSelected = (selectedItems, item) => {
    const data = selectedItems !== undefined && selectedItems ? selectedItems : []
    let returnData = []
    if (data.includes(item)) {

      returnData = data.filter((x) => x !== item)
    } else {
      returnData = [...data, item]
    }
    return returnData
  }


  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <Box
            gap={1}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
            }}
          >
            {options.map((option, index) => (

              <Stack key={`${name}_${index}`} sx={{ border: value !== undefined && value.includes(option[`${optionValue}`]) ? '1px solid green' : '1px solid #d3d3d3', borderRadius: '10px', padding: '10px' }} >
                <FormControlLabel
                  key={option[`${optionValue}`]}
                  control={
                    <Checkbox
                      checked={value !== undefined && value.includes(option[`${optionValue}`])}
                      onChange={() => field.onChange(getSelected(value, option[`${optionValue}`]))}
                    />
                  }
                  sx={{ color: value !== undefined && value.includes(option[`${optionValue}`]) && 'green' }}
                  label={option[`${optionLabel}`]}
                  {...other}
                />
              </Stack>

            ))}
          </Box>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
