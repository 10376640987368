// Bear in mind that this is a demo project and the project name is hardcoded
const url = window.location.hostname === "localhost" ? "cms.myemporia.my" : window.location.hostname
// const domainName = url.split(".")[1]
const domainName ='emporia';

export const ServerConfiguration = {
  // APIServerUrl: `https://${url}/eCommerceCMS/api/${domainName}/`, 
  APIServerUrl: `https://${url}/eCommerceCMS/api/${domainName}/`,     // DEV
  // testingServerUrl: "http://10.64.222.135/TaskMateAPI/api/testapi/",  // Teoh mentioned use this as der server url (PC1)
  // testingServerUrl: "https://triviix.com/testapi/api/testapi/",
  // liveServerUrl: "https://taskmate.click/TaskMateApi/api/TaskMateApi/",
  // uploadUrl: "https://triviix.com/TaskMate_UploadedFiles/upload.php",
  uploadUrl : "https://cms.emporia.my/eCommerceCMSImage/upload.php",
  uploadUrlNoID : "https://cms.emporia.my/eCommerceCMSImage/uploadWithoutID.php",
  PROJECTID: 2
}