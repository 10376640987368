import sum from 'lodash/sum';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from '../../utils/axios';
import { ServerConfiguration } from '../../utils/serverConfig';

// ----------------------------------------------------------------------

const url = ServerConfiguration.APIServerUrl
const initialState = {
  isLoading: false,
  error: null,
  addSpecificationAction: null,
  updateSpecificationAction: null,
  deleteSpecificationAction: null,
};

const slice = createSlice({
  name: 'specification',
  initialState,
  reducers: {

    // Start Loading
    startLoading(state) {
      state.isLoading = true;
    },

    // has Error
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    addSpecificationSuccess(state, action) {
      state.isLoading = false;
      state.addSpecificationAction = action.payload;
    },

    updateSpecificationSuccess(state, action) {
      state.isLoading = false;
      state.updateSpecificationAction = action.payload;
    },

    deleteSpecificationSuccess(state, action) {
      state.isLoading = false;
      state.deleteSpecificationAction = action.payload;
    },

    clearSpecificationActionSuccess(state, action) {
      state.isLoading = false;
      state.deleteSpecificationAction = null;
      state.updateSpecificationAction = null;
      state.addSpecificationAction = null;
    },



  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  Category_ViewList,
} = slice.actions;

// ----------------------------------------------------------------------

export function addSpecification(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_AddProductSpecificationDetail?PRODUCTVARIATIONID=${productData.specificationTypeId}&PRODUCTID=${productData.productId}&PRODUCTSPECIFICATIONVALUE=${productData.specification}&USERID=${productData.userId}`);
    
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.addSpecificationSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.addSpecificationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateSpecification(productData) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(`${url}Product_UpdateProductSpecificationDetail?PRODUCTSPECIFICATIONDETAILID=${productData.specId}&PRODUCTVARIATIONID=${productData.specificationTypeId}&PRODUCTID=${productData.productId}&PRODUCTSPECIFICATIONVALUE=${productData.specification}&USERID=${productData.userId}`);
    
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.updateSpecificationSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.updateSpecificationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSpecification(specId, userId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch(
        `${url}Product_DeleteProductSpecificationDetail?PRODUCTSPECIFICATIONDETAILID=${specId}&USERID=${userId}`
      )
      const json = await response.json();      
      const data = JSON.parse(json)[0];
      if (data && data.ReturnVal === 1) {
      dispatch(slice.actions.deleteSpecificationSuccess(JSON.parse(data.ReturnData)));
      }else {
        dispatch(slice.actions.deleteSpecificationSuccess([]));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearSpecificationAction() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
        dispatch(slice.actions.clearSpecificationActionSuccess([]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

